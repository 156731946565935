import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type PageHeaderMetaItem = {
  icon: React.ElementType;
  label: string | React.ReactNode;
  href?: string;
};

type PageHeaderBreadcrumbs = {
  label: string;
  href: string;
};

type PageHeader = {
  className?: string;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  metaItems?: PageHeaderMetaItem[];
  breadcrumbs?: PageHeaderBreadcrumbs[];
  children?: React.ReactNode;
};

export default function PageHeader({
  className = "",
  title,
  subtitle = undefined,
  metaItems = [],
  breadcrumbs = [],
  children = null
}: PageHeader) {
  return (
    <div
      className={twMerge(
        "lg:flex lg:items-center lg:justify-between",
        className
      )}
    >
      <div className="min-w-0 flex-1">
        {Number(breadcrumbs.length) > 0 && (
          <nav aria-label="Breadcrumb" className="flex">
            <ol className="flex items-center space-x-4 mb-2">
              {breadcrumbs.map((crumb, idx) => (
                <li key={idx}>
                  <div className="flex items-center">
                    {idx > 0 && (
                      <ChevronRightIcon
                        aria-hidden="true"
                        className="size-4 shrink-0 text-cello-400"
                      />
                    )}
                    <Link
                      className={twMerge(
                        "text-sm font-medium text-cello-500 hover:text-cello-700 hover:underline",
                        idx > 0 ? "ml-4" : ""
                      )}
                      to={crumb.href}
                    >
                      {crumb.label}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        )}
        <h2 className="text-2xl lg:mb-2 font-display leading-7 text-cello-500 sm:truncate sm:text-3xl sm:tracking-tight">
          {title}
        </h2>
        {subtitle && (
          <h3 className="text-lg font-accent text-cello-300">{subtitle}</h3>
        )}
        <div className="mt-1 flex flex-col md:mt-4 md:flex-row md:flex-wrap md:space-x-6">
          {metaItems.map((item, idx) => {
            const MetaContent = (
              <div
                key={idx}
                className="flex items-center text-sm text-cello-500"
              >
                <item.icon
                  aria-hidden="true"
                  className="mr-1.5 size-5 shrink-0 text-cello-400"
                />
                {item.label}
              </div>
            );

            return item.href ? (
              <a
                key={idx}
                className="no-underline text-inherit p-2 hover:bg-slate-200 hover:rounded"
                href={item.href}
              >
                {MetaContent}
              </a>
            ) : (
              MetaContent
            );
          })}
        </div>
      </div>
      <div className="mt-5 items-center flex flex-col md:flex-row lg:ml-4 lg:mt-0 gap-y-2">
        {children}
      </div>
    </div>
  );
}
